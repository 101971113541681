<template>
  <div v-if="display === 'list'">
    <dl class="table table-fixed w-full">
      <div
        class="table-row w-full"
        v-for="(value, key) in data"
        v-bind:key="key"
      >
        <dt class="text-gray-dark pr-2 table-cell w-1/4" :title="key">
          <div class="flex">
            <span class="max-w-key inline-block truncate">{{ key }}</span>
            <span>:</span>
          </div>
        </dt>
        <dd class="text-black table-cell w-3/4" :title="value">
          <div class="truncate no-wrap">{{ value }}</div>
        </dd>
      </div>
    </dl>
  </div>
  <div v-else-if="display === 'code'">
    <pre
      class="overflow-scroll text-tiny line-numbers"
      :data-start="codeStartLine"
    ><code ref="codeBlock" class="language-json">{{data}}</code></pre>
  </div>
</template>

<script>
import Prism from 'prismjs'
export default {
  name: 'DataViewer',
  props: {
    data: {
      type: Object,
      validator: (data) =>
        Object.keys(data).every(
          (key) => typeof key === 'string' && typeof data[key] === 'string'
        ),
    },
    display: {
      type: String,
      default: 'list',
      validator: (display) => {
        const whitelist = ['list', 'code']
        return whitelist.indexOf(display) !== -1
      },
    },
    startLine: {
      type: String,
      default: '1',
      validator: (line) => !isNaN(parseInt(line, 10)),
    },
  },
  computed: {
    codeStartLine() {
      const line = parseInt(this.startLine, 10)
      if (isNaN(line) || line === 1) {
        return false
      }
      return line
    },
  },
  methods: {
    renderPrsim() {
      if (this.$refs.codeBlock) {
        Prism.highlightElement(this.$refs.codeBlock)
      }
    },
  },
  mounted() {
    this.renderPrsim()
  },
  updated() {
    this.renderPrsim()
  },
}
</script>

<style></style>
