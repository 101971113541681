<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="p-4 border-t-2 border-b-2 border-tozstore flex flex-col w-72">
      <p class="mb-8">{{ message }}</p>
      <ToznyButton
        v-if="!!actionText"
        class="self-center mb-0"
        @click.native.prevent="$emit('action')"
        :buttonText="actionText"
      />
    </div>
  </div>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'RecordError',
  components: {
    ToznyButton,
  },
  props: {
    message: String,
    actionText: String,
  },
}
</script>
